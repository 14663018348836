export const distributionDataSteps = [
  {
    key: "welcome",
    target: ".intro-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "channels-table",
    target: ".intro-channels-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "details",
    target: ".intro-details",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];


export const upstreamDistributionDataSteps = [
  {
    key: "welcome",
    target: ".intro-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "channels-table",
    target: ".intro-channels-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "details",
    target: ".intro-details",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
