import { useSelector } from "react-redux";
import {
  selectAdData,
  selectCoreData,
  selectDistributionData,
  selectEmployees,
  selectEmployeeSurveyOpen,
  selectFleetData,
  selectForecastingData,
  selectHasPurchases,
  selectInvestmentProviders,
  selectIsFreemium,
  selectMaterialsData,
  selectPackagingData,
  selectPensionProviders,
  selectSites,
  selectSuppliers,
  selectTravelData,
  selectUpstreamDistributionData,
  selectVolunteerData,
  selectWebData,
} from "src/store/projectSelectors";

import { SECTOR_KEYS } from "src/constants/sectorKeys";
import { useSectionOpen, useSectors } from "src/hooks";
import useCompletedFunctions from "./hooks/useCompletedFunctions";

/**
 * Calculates the completion rate of the organisation
 *
 * @returns {{calculateSectorCompletion: function,calculateSiteDataCompl:function,calculateFreemiumCompletion:function calculateTotalCompletion: function}} Object with functions:
 * - `calculateSectorCompletion` - calculates the completion rate of the sector
 * - `calculateSiteDataCompl` - calculates the completion rate of the site data
 * - `calculateFreemiumCompletion` - calculates the completion rate of the freemium organisation
 * - `calculateTotalCompletion` - calculates the completion rate of the organisation
 *
 */

const useCompletedRate = () => {
  const supplierData = useSelector(selectSuppliers);
  const coreData = useSelector(selectCoreData);
  const fleetData = useSelector(selectFleetData);

  const employeeData = useSelector(selectEmployees);
  const adData = useSelector(selectAdData);
  const travelData = useSelector(selectTravelData);
  const investmentProviders = useSelector(selectInvestmentProviders);
  const pensionProviders = useSelector(selectPensionProviders);
  const investmentData = useSelector(
    (state) => state.project.details?.investmentData
  );
  const forecastingData = useSelector(selectForecastingData);
  const sites = useSelector(selectSites);

  const hasPurchases = useSelector(selectHasPurchases);
  const surveyOpenDate = useSelector(selectEmployeeSurveyOpen);
  const volunteerData = useSelector(selectVolunteerData);
  const materialsData = useSelector(selectMaterialsData);
  const packagingData = useSelector(selectPackagingData)
  const distributionData = useSelector(selectDistributionData)
  const upstreamDistributionData = useSelector(selectUpstreamDistributionData)

  const webData = useSelector(selectWebData)


  const isFree = useSelector(selectIsFreemium);

  const { SECTORS } = useSectors();

  const {
    calculateCoreCompl,
    calculateSiteDataCompl,
    calculateEmployeeCompl,
    calculateForecastingCompl,
    calculatePurchaseCompl,
    calculateSuppliersCompl,
    calculateVehicleCompl,
    calculateTravelCompl,
    calculateAdCompl,
    calculateInvestmentDataCompl,
    calculateVolunteerCompl,
    calculateMaterialsCompl,
    calculateDistributionCompl,
    calculateWebDataCompl
  } = useCompletedFunctions();

  const calculateSectorCompletion = (sectionKey) => {
    let result;

    switch (sectionKey) {
      case SECTOR_KEYS.coreData:
        result = calculateCoreCompl(coreData, sites?.length);
        break;
      case SECTOR_KEYS.forecastingData:
        result = calculateForecastingCompl(forecastingData);
        break;

      case SECTOR_KEYS.purchaseData:
        result = calculatePurchaseCompl(hasPurchases);
        break;

      case SECTOR_KEYS.employeeData:
        result = calculateEmployeeCompl(employeeData, !!surveyOpenDate);
        break;

      case SECTOR_KEYS.volunteerData:
        result = calculateVolunteerCompl(volunteerData);
        break;
      case SECTOR_KEYS.siteData:
        result = calculateSiteDataCompl(
          sites,
          !coreData?.noLocations,
          isFree
        ).totalRate;
        break;

      case SECTOR_KEYS.fleetData:
        result = calculateVehicleCompl(fleetData);
        break;

      case SECTOR_KEYS.suppliersData:
        result = calculateSuppliersCompl(supplierData);
        break;

      case SECTOR_KEYS.adData:
        result = calculateAdCompl(adData);
        break;

      case SECTOR_KEYS.travelData:
        result = calculateTravelCompl(travelData);
        break;
      case SECTOR_KEYS.materialsData:
        result = calculateMaterialsCompl(materialsData);
        break;
      case SECTOR_KEYS.packagingData:
        result = calculateMaterialsCompl(packagingData);
        break;
      case SECTOR_KEYS.distributionData:
        result = calculateDistributionCompl(distributionData);
        break;

      case SECTOR_KEYS.upstreamDistributionData:
        result = calculateDistributionCompl(upstreamDistributionData);
        break;

      case SECTOR_KEYS.investmentData:
        result = calculateInvestmentDataCompl(
          investmentData,
          pensionProviders,
          investmentProviders
        );
        break;
      case SECTOR_KEYS.webData:
        result = calculateWebDataCompl(webData);
        break;
      default:
        result = 0;
    }

    return result;
  };

  const calculateMultipleSectorsCompletion = (items) => {
    if (items.length) {
      const calculatedArray = items.map((item) =>
        calculateSectorCompletion(item.name)
      );
      const totalValue =
        calculatedArray.reduce((acc, curr) => acc + curr) / items.length;

      return totalValue * 100;
    } else {
      return 0;
    }
  };

  const calculateFreemiumCompletion = () =>
    calculateMultipleSectorsCompletion(
      SECTORS.filter((sector) =>
        [
          SECTOR_KEYS.coreData,
          SECTOR_KEYS.siteData,
          SECTOR_KEYS.forecastingData,
          SECTOR_KEYS.fleetData,
        ].includes(sector.name)
      )
    );

  const { checkIsOpen } = useSectionOpen();

  const calculateTotalCompletion = () =>
    calculateMultipleSectorsCompletion(
      SECTORS.filter((item) => !checkIsOpen(item.name).isHidden)
    );

  return {
    calculateSectorCompletion,
    calculateSiteDataCompl,
    calculateFreemiumCompletion,
    calculateTotalCompletion,
  };
};

export default useCompletedRate;
