import { useEffect, useState } from "react";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, usePermissions } from "..";
import { useDoc } from "../databaseHooks";


function convertDateString(period) {
  const [year, month, day] = period.split("-");

  return `${day}.${month}.${year}`;
}

const useReportingPeriod = () => {
  const { isSurveyRespondent } = usePermissions();

  const [reportingPeriod, setReportingPeriod] = useState("");
  const [periodName, setPeriodName] = useState("");
  const [isSurvey, setIsSurvey] = useState(false);

  const { getPath } = useDatabasePaths();

  const { data } = useDoc(getPath(DB_PATHS.coreData));
  const { data: periodData } = useDoc(getPath(DB_PATHS.period));

  useEffect(() => {
    if (isSurveyRespondent) {
      setIsSurvey(true);
    }
  }, [isSurveyRespondent]);

  useEffect(() => {
    if (!periodData?.["period-name"]) return;
    setPeriodName(periodData?.["period-name"]);
  }, [periodData]);

  useEffect(() => {
    if (!data?.["report-date-from"] && !data?.["report-date-to"]) {
      setReportingPeriod(null);
      return;
    }

    setReportingPeriod(
      `${convertDateString(data["report-date-from"])} - ${convertDateString(
        data["report-date-to"]
      )}`
    );
  }, [data]);

  return { reportingPeriod, periodName, isSurvey };
};

export default useReportingPeriod;
