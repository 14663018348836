import { kebabCase } from "lodash"
import { useSelector } from "react-redux"
import { selectOrgData, selectSites } from "src/store/projectSelectors"
import exioCountries from "src/constants/exio-countries.json";

const FACTOR_SETS = {
    BEIS: "beis",
    EXIO: "exio",
    EPA: "epa",
    old: "old"
}


export const useFactorSets = () => {

    const sites = useSelector(selectSites)

    const findPrimaryCountry = (siteData) => {
        try {
            if (!siteData?.length) return "United Kingdom of Great Britain and Northern Ireland (UK)"

            if (siteData.length === 1) return siteData[0].country

            const countries = [...new Set(siteData.map(site => site.country))]

            if (countries.length === 1) return countries[0]

            let maxEnergy = 0
            let maxCountry = countries[0]

            countries.forEach(country => {
                const total = siteData.filter(site => site.country === country).reduce((acc, site) => acc + (site.energy || 0), 0)
                if (total > maxEnergy) {
                    maxEnergy = total
                    maxCountry = country
                }
            })

            return maxCountry

        } catch (error) {
            console.error("Error finding primary country", error)
            return "United Kingdom of Great Britain and Northern Ireland (UK)"
        }
    }

    const primaryCountry = findPrimaryCountry(sites);

    const { calcVersion = 1 } = useSelector(selectOrgData)


    const checkFactorsToUse = (purchase) => {

        if (calcVersion === 1) {
            return FACTOR_SETS.old
        }

        if (purchase.currency === "GBP" || primaryCountry?.includes("United Kingdom")) {
            return FACTOR_SETS.BEIS
        }
        if (purchase.currency === "USD" || primaryCountry?.includes("United States")) {
            return FACTOR_SETS.EPA
        }
        return FACTOR_SETS.EXIO
    }

    const findExioRegion = (currency) => {

        const firstCurrencyObj = exioCountries.find(c => c.Currency === currency)
        const firstCurrencyIdx = exioCountries.indexOf(firstCurrencyObj)
        const nextCurrencyObj = exioCountries.slice(firstCurrencyIdx + 1).find(c => c.Currency === currency)

        /* If a unique country can be found from currency, return it */
        const doesCurrencyIdentifyUniqueCountry = firstCurrencyObj && !nextCurrencyObj

        if (doesCurrencyIdentifyUniqueCountry) {
            /* If object has a Region, it means it doesn't have it's own EF */
            return kebabCase(firstCurrencyObj.Region || firstCurrencyObj.Country)
        }

        /* Find region or country based on primary country from Sites */
        const countryObj = exioCountries.find(c => c.Country === primaryCountry)
        return kebabCase(countryObj?.Region || countryObj?.Country)
    }






    return { checkFactorsToUse, primaryCountry, calcVersion, findExioRegion }
}

