import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { adapator_createDatabaseRef } from "src/adapters"
import { selectOrgId, selectPeriod } from "src/store/projectSelectors"
import { useDoc } from "../databaseHooks"
import useDatabasePaths from "../useDatabasePaths/useDatabasePaths"
import { DB_PATHS } from "src/constants"

const useLatestLog = () => {
    const [log, setLog] = useState({})
    const [status, setStatus] = useState("idle")
    const orgId = useSelector(selectOrgId)
    const periodId = useSelector(selectPeriod)

    const { getPath } = useDatabasePaths();
    const { data: periodData } = useDoc(getPath(DB_PATHS.period));
    const { currentLogTimestamp } = periodData || {}

    useEffect(() => {
        if (!orgId || !periodId) return
        async function fetchLog() {
            setStatus("loading")

            let logSnapshot

            const logsRef = adapator_createDatabaseRef(["organisations", orgId, "periods", periodId, "result-logs"])
            if (currentLogTimestamp) {
                logSnapshot = await logsRef.where("created", "==", currentLogTimestamp).get()
                if (logSnapshot.empty) {
                    logSnapshot = await logsRef.orderBy("created", "desc").limit(1).get()
                }
            } else {
                logSnapshot = await logsRef.orderBy("created", "desc").limit(1).get()
            }

            if (logSnapshot.empty) return
            const doc = logSnapshot.docs[0]
            const log = { ...doc.data(), id: doc.id }
            setLog(log)
            setStatus("success")
        }

        fetchLog()
    }, [orgId, periodId, currentLogTimestamp])

    return { log, status }
}

export default useLatestLog