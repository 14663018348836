import { lowerFirst } from "lodash";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch } from "react-redux";
import { analytics } from "src/adapters/common-set-up/firebase";
import zeroBeesLogo from "src/assets/images/ZeroBees_logo_new.svg";
import { DB_PATHS } from "src/constants";
import {
  showWelcomeSectionModal,
  showWelcomeTutorial
} from "src/store/modalSlice";
import { useDatabasePaths, usePermissions, useUpdateDatabase } from "..";
import { useDoc } from "../databaseHooks";
import {
  adDataIntroSteps,
  coreDataIntroCollaboratorStep,
  coreDataIntroSteps, dashboardIntroSteps, employeeDataIntroSteps,
  fleetDataIntroSteps,
  forecastingDataIntroSteps,
  investmentDataIntroSteps,
  purchaseDataIntroSteps,
  resultIntroSteps,
  siteDataIntroSteps,
  supplierDataIntroSteps,
  travelDataIntroSteps,
  materialsDataSteps,
} from "./constants/steps";
import { webDataSteps } from "./constants/steps/webDataSteps";
import { distributionDataSteps, upstreamDistributionDataSteps } from "./constants/steps/distributionDataSteps";

const useSectionIntro = (section = null, customCallback) => {

  const [introSteps, setIntroSteps] = useState();
  const [stepIndex, setStepIndex] = useState(0);
  const { isAdmin, isSuperAdmin } = usePermissions();

  const { updateDocument } = useUpdateDatabase();
  const { getPath } = useDatabasePaths();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const introFinalStep = {
    title: (
      <>
        {t("tutorials.intro.title")}
        <img
          className="d-block mx-auto"
          src={zeroBeesLogo}
          alt="ZeroBees Logo"
        />
      </>
    ),
    target: ".intro-final",
    content: t("tutorials.intro.content"),
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      close: "Close",
    },
  };

  const addTitleAndContentToSteps = (steps) =>
    Object.fromEntries(
      Object.entries(steps).map((entry) => {
        const [sectionName, sectionSteps] = entry;

        const newSteps = sectionSteps.map((step) => {
          const stringPath = `tutorials.${lowerFirst(sectionName)}.${step.key}`;

          let content = !step.paragraphs ? (
            t(`${stringPath}.content`)
          ) : (
            <Trans
              i18nKey={`${stringPath}.content`}
              components={{
                1: <p style={{ fontSize: "20px" }} />,
                2: <p className="mb-1" />,
                3: <p className="mb-1" />,
              }}
            ></Trans>
          );

          return {
            ...step,
            title: t(`${stringPath}.title`),
            content,
          };
        });

        return [sectionName, newSteps];
      })
    );

  const dashboardSteps = [
    ...addTitleAndContentToSteps({ dashboard: dashboardIntroSteps }).dashboard,
    introFinalStep,
  ];

  const getSectionSteps = () => {
    if (!section) return;

    let steps = {
      CoreData: isAdmin
        ? [...coreDataIntroSteps, coreDataIntroCollaboratorStep]
        : coreDataIntroSteps,
      SiteData: siteDataIntroSteps,
      EmployeeData: employeeDataIntroSteps,
      ForecastingData: forecastingDataIntroSteps,
      FleetData: fleetDataIntroSteps,
      PurchaseData: purchaseDataIntroSteps,
      SupplierData: supplierDataIntroSteps,
      TravelData: travelDataIntroSteps,
      InvestmentData: investmentDataIntroSteps,
      AdData: adDataIntroSteps,
      Results: resultIntroSteps,
      WebData: webDataSteps,
      MaterialsData: materialsDataSteps,
      PackagingData: materialsDataSteps,
      DistributionData: distributionDataSteps,
      UpstreamDistributionData: upstreamDistributionDataSteps
    };

    steps = addTitleAndContentToSteps(steps);

    return [...steps[section], introFinalStep];
  };

  const { data: userData } = useDoc(getPath(DB_PATHS.user));

  const controlledCallbackHandler = (data) => {
    const { action, type, index, status } = data;

    if (type === "tour:end") {
      action === "skip"
        ? analytics.logEvent("tutorial_skip")
        : analytics.logEvent("tutorial_complete");
      setStepIndex(0);
      dispatch(showWelcomeSectionModal(false));
      dispatch(showWelcomeTutorial(false));

      if (userData?.[`complete${section}Intro`]) return;
      updateDocument(DB_PATHS.user, { [`complete${section}Intro`]: true });
    }

    customCallback?.(data);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  const callbackHandler = (data) => {
    const { action, type } = data;

    if (type === "tour:end") {
      action === "skip"
        ? analytics.logEvent("tutorial_skip")
        : analytics.logEvent("tutorial_complete");

      dispatch(showWelcomeSectionModal(false));
      dispatch(showWelcomeTutorial(false));

      if (userData?.[`complete${section}Intro`]) return;
      updateDocument(DB_PATHS.user, { [`complete${section}Intro`]: true });
    }
  };

  useEffect(() => {
    if (isSuperAdmin) return;
    if (!section) return;

    const steps = getSectionSteps();
    setIntroSteps(steps);

    if (userData?.[`complete${section}Intro`]) return;
    dispatch(showWelcomeSectionModal(true));
  }, [userData, section]);

  const updateIndex = (action = "next") => {
    setStepIndex((prev) => (action === "next" ? prev + 1 : prev - 1));
  };

  return {
    callbackHandler,
    introSteps,
    controlledCallbackHandler,
    stepIndex,
    updateIndex,
    dashboardSteps,
  };
};

export default useSectionIntro;
