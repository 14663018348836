import { getAuth } from "firebase/auth"; // Firebase v9+
import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore"; // Firebase v9+
import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AuthProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { Loading, Toast } from "./components";
import OfflineAlert from "./components/OfflineAlert/OfflineAlert";
import { useOnlineStatus, useToasts } from "./hooks";
import { TheSurveyLayout } from "./layout";
import EmployeeSurvey from "./pages/employeeSurvey";
import SupplierSurvey from "./pages/supplierSurvey";
import "./scss/style.scss";
import { resetModals } from "./store/modalSlice";
import { setOrg } from "./store/projectSlice";
import { resetUserData } from "./store/userSlice";

// Containers
const TheIsAuthedPage = React.lazy(() => import("./layout/TheIsAuthedPage"));
const TheSurveyAuth = React.lazy(() => import("./layout/TheSurveyAuth"));
const TheSuperSignupPage = React.lazy(() =>
  import("./layout/TheSuperSignupPage")
);

const TravelSurvey = React.lazy(() => import("./pages/travelSurvey"));

const TravelSurveyLanding = React.lazy(() =>
  import("./pages/travelSurveyLanding")
);

const SignupPage = React.lazy(() => import("./pages/signupPage/SignupPage"));
const PensionCalculator = React.lazy(() =>
  import("./pages/pensionCalculator/PensionCalculator")
);

const App = () => {
  let app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const database = getFirestore(app);
  const auth = getAuth(app);
  const dispatch = useDispatch();

  const { onlineStatus: isOnline } = useOnlineStatus();

  useEffect(() => {
    if (window.location.href.includes("pension-calculator")) {
      return;
    }
    const script = document.createElement("script");
    script.src = "//js-eu1.hs-scripts.com/25419865.js";
    script.type = "text/javascript";
    script.async = true;
    document.getElementsByTagName("body")[0].appendChild(script);
  }, []);

  const { showToast } = useToasts();

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      const decodedtoken = await auth.currentUser.getIdTokenResult();
      const org = decodedtoken.claims.org || "none";
      const role = decodedtoken.claims.role;

      if (role === "esg-user") {

        showToast({ text: "ESG survey accounts are not authorized to access this page", type: "error" });
        auth.signOut();
      }

      dispatch(setOrg(org));

      dispatch(
        resetUserData({
          organisation: org,
          role,
          token: user.accessToken,
          email: user.email,
          firstName: user.displayName,
          uid: user.uid,
          emailVerified: user.emailVerified,
        })
      );
      dispatch(resetModals());

      if (process.env.NODE_ENV === "production" && role !== "superAdmin") {
        LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email,
          userRole: role,
        });
      }
    }

    if (!user) {
      firebase.firestore().terminate();
    }
  });

  // emulators for testing
  // firebase.auth().useEmulator("http://localhost:9099");
  // firebase.firestore().useEmulator("localhost", 8080);

  const { t } = useTranslation();

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={database}>
        {!isOnline && <OfflineAlert />}
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                path="/signup"
                exact
                name="Signup"
                render={(props) => <SignupPage {...props} />}
              />
              <Route
                path="/signup/super"
                exact
                name="Super Signup"
                render={(props) => <TheSuperSignupPage {...props} />}
              />
              <Route
                path="/:orgId/:periodId/supplier-survey/demo"
                exact
                name="Supplier Survey Demo"
                render={(props) => (
                  <TheSurveyLayout>
                    <SupplierSurvey isDemo {...props} />
                  </TheSurveyLayout>
                )}
              />
              <Route
                path="/:orgId/:periodId/supplier-survey"
                name="Supplier Survey"
                render={(props) => (
                  <TheSurveyAuth surveyType="supplier" {...props} />
                )}
              />
              <Route
                path="/:orgId/:periodId/survey/demo"
                exact
                name="Employee Survey Demo"
                render={(props) => (
                  <TheSurveyLayout>
                    <EmployeeSurvey isDemo {...props} />
                  </TheSurveyLayout>
                )}
              />
              <Route
                path="/:orgId/:periodId/survey"
                name="Employee Survey"
                render={(props) => (
                  <TheSurveyAuth surveyType="employee" {...props} />
                )}
              />
              <Route
                path="/:orgId/:periodId/:siteId/travel-survey"
                name="Travel Survey"
                render={(props) => <TravelSurvey {...props} />}
              />
              <Route
                path="/:orgId/:periodId/:siteId/travel-survey-landing"
                name="Travel Survey"
                render={(props) => <TravelSurveyLanding {...props} />}
              />
              <Route
                path="/:orgId/:periodId/assessment-survey"
                name="Assessment Survey"
                render={(props) => (
                  <TheSurveyAuth surveyType="assessment" {...props} />
                )}
              />
              <Route
                path="/pension-calculator"
                name="Pension Calculator"
                render={(props) => <PensionCalculator {...props} />}
              />
              <Route
                path="/"
                name={t("routes.home")}
                render={(props) => <TheIsAuthedPage {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
        <Toast />
      </FirestoreProvider>
    </AuthProvider>
  );
};

export default App;
