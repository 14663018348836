export const selectProject = ({ project }) => project;
export const selectDetails = ({ project: { details } }) => details;
export const selectCoreData = (state) => state.project?.details?.coreData;
export const selectFleetData = (state) => state.project?.details?.fleetData;
export const selectPassengerVehicles = (state) =>
  state.project?.details?.fleetData?.passengerVehicles;
export const selectForecastingData = (state) =>
  state.project?.details?.forecastingData;

export const selectTravelData = (state) => state.project?.details?.travelData;
export const selectMaterialsData = (state) =>
  state.project?.details?.materialsData;

export const selectPackagingData = (state) =>
  state.project?.details?.packagingData;

export const selectDistributionData = (state) =>
  state.project?.details?.distributionData;

export const selectUpstreamDistributionData = (state) =>
  state.project?.details?.upstreamDistributionData;

export const selectWebData = (state) => state.project?.details?.webData;

export const selectPurchaseData = (state) =>
  state.project?.details?.purchaseData;
export const selectVolunteerData = (state) =>
  state.project?.details?.volunteerData;
export const selectDentistData = (state) => state.project?.details?.dentistData;

export const selectSectorCompleted = (state) =>
  Object.values(state.project.details).map((sector) => {
    return { id: sector.docId, completed: sector.highestCompleted || 0 };
  });

export const selectSites = (state) => state.project.sites;
export const selectFormatCategories = (state) =>
  state.project?.details?.adData?.formatCategories;
export const selectOrgId = (state) =>
  state.project?.data?.organisation || "none";
export const selectOrgName = (state) => state.project?.data?.name;
export const selectOrgData = (state) => state.project?.data;
export const selectOwner = (state) => state.project?.data?.owner;
export const selectPeriod = (state) => state.project?.data?.period;
export const selectEndDate = (state) =>
  state.project?.details?.coreData.reportDateTo;

export const selectWebsite = (state) => state.project?.details?.coreData?.website

export const selectOwnerId = (state) => state.project?.data?.ownerId;

export const selectIsFreemium = (state) => state.project?.data?.freemium;

export const selectDayUpdates = (state) => state.project?.data?.dayUpdates;
export const selectReminders = (state) => state.project?.data?.reminders;

export const selectTasks = (state) => state.project?.data?.tasks;
export const selectCompletedTasks = (state) =>
  state.project?.data?.completedTasks;
export const selectProjectData = (state) => state.project?.data;
export const selectSiteLookup = (state) => state.project?.siteLookup;
export const selectSuppliers = (state) => state.project?.suppliers;
export const selectPurchases = (state) => state.project?.purchases;

export const selectSoftware = (state) =>
  state.project.details?.purchaseData?.software;
export const selectEmployeeCountStart = (state) =>
  state.project.details?.coreData?.employeesCountStart;
export const selectEmployeeCountEnd = (state) =>
  state.project.details?.coreData?.employeesCountEnd;
export const selectTurnoverAmount = (state) =>
  state.project.details?.coreData?.turnoverAmount;
export const selectTurnoverCurrency = (state) =>
  state.project.details?.coreData?.turnoverCurrency;
export const selectEmployees = (state) => state.project?.employees;
export const selectAdData = (state) => state.project?.details?.adData;
export const selectPensionProviders = (state) => state.project.pensionProviders;
export const selectInvestmentProviders = (state) =>
  state.project.investmentProviders;

export const selectBanks = (state) =>
  state.project.details?.investmentData?.banks;

export const selectEmployeePassword = (state) =>
  state.project?.data?.["employee-password"];

export const selectSupplierPassword = (state) =>
  state.project?.data?.["supplier-password"];

export const selectEmployeeSurveyOpen = (state) =>
  state.project?.details?.employeeData?.surveyOpenFrom;

export const selectSupplierSurveyOpen = (state) =>
  state.project?.details?.supplierData?.surveyOpenFrom;

export const selectDaysInWorkWeek = (state) =>
  state.project?.settings?.constants?.["DAYS_IN_WORK_WEEK"]?.current || 5;

export const selectRecommendations = (state) =>
  state.project?.details?.forecastingData?.recommendations;

export const selectAutoDisabled = (state) =>
  state.project?.details?.forecastingData?.autoDisabled;

export const selectStartDate = (state) =>
  state.project?.details?.coreData?.reportDateFrom;

/******SETTINGS*******/
export const selectSettings = (state) => state.project?.settings;
export const selectSectionSettings = (state) =>
  state.project?.settings?.sections;
export const selectProjectConstants = (state) =>
  state.project?.settings?.constants;

export const selectPeriodSettings = (state) => state.project?.periodSettings;
export const selectOverrides = (state) =>
  state.project?.periodSettings?.overrides;
export const selectProjectCategories = (state) =>
  state.project?.periodSettings?.categories;
export const selectAdminCommentary = (state) =>
  state.project?.periodSettings?.commentary;
export const selectFilters = (state) => state.project?.periodSettings?.filters;
export const selectCategoriesFactors = (state) =>
  state.project?.periodSettings?.["categories-factors"];
export const selectSupplierOverrides = (state) =>
  state.project?.periodSettings?.["supplier-overrides"];

export const selectCategoriesOrder = (state) =>
  state.project?.periodSettings?.["categories-order"];

export const selectSuppliersToIgnore = (state) =>
  state.project?.periodSettings?.["supplier-ignore"];

export const selectSector = (state) =>
  state.project?.details?.coreData?.industrySector;

export const selectLog = (state) => state.project?.log;

export const selectAssessmentSurvey = (state) => state.project?.assessmentSurvey;

export const selectEsgSurveyOpen = (state) =>
  state.project?.data?.["show-survey"] || false;

export const selectCurrentEsgSurvey = (state) =>
  state.project?.details?.assessmentSurvey?.currentVersion

export const selectHasPurchases = (state) => state.project?.hasPurchases;

export const selectMacroOverrides = (state) => state.project?.periodSettings?.["macro_overrides"]

export const selectIsArchived = (state) => state.project?.data?.archived