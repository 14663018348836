import { cloneDeep, get } from "lodash";
import { RESULT_KEYS } from "src/constants/resultsSections";

export const convertResults = (results, projectCategories) => {
  if (!results) return;

  const [scope1, scope2, scope3, sites] = results;
  const resultsObj = {};

  const copiedSites = cloneDeep(sites || {});

  delete copiedSites["NO_ID_FIELD"];

  resultsObj.locations = copiedSites;
  resultsObj[RESULT_KEYS.homeElec] = get(
    scope3,
    "office-home.home-energy.electricity",
    0
  );
  resultsObj[RESULT_KEYS.homeGas] = get(
    scope3,
    "office-home.home-energy.gas",
    0
  );
  resultsObj[RESULT_KEYS.homePaper] = get(
    scope3,
    "office-home.home-paper.paper",
    0
  );
  resultsObj[RESULT_KEYS.commutePrivate] = get(
    scope3,
    "employee-commute.vehicles",
    0
  );
  resultsObj[RESULT_KEYS.commutePublic] = [
    "bus",
    "coach",
    "tram",
    "taxi",
    "rail",
    "metro",
  ].reduce((acc, val) => acc + get(scope3, `employee-commute.${val}`, 0), 0);
  resultsObj[RESULT_KEYS.commuteHotels] = get(
    scope3,
    "employee-commute.hotels",
    0
  );
  resultsObj[RESULT_KEYS.commuteVolunteers] = ["bus", "car"].reduce(
    (acc, val) => acc + get(scope3, `volunteer-commute.${val}`, 0),
    0
  );
  resultsObj[RESULT_KEYS.passengerFleet] = {
    scope1: get(scope1, "fleet-data.passenger-vehicles"),
    scope2: get(scope2, "fleet-data.passenger-vehicles"),
    scope3: get(scope3, "fleet-data.passenger-vehicles"),
  };
  resultsObj[RESULT_KEYS.deliveryFleet] = {
    scope1: get(scope1, "fleet-data.delivery-vehicles"),
    scope2: get(scope2, "fleet-data.delivery-vehicles"),
    scope3: get(scope3, "fleet-data.delivery-vehicles"),
  };
  resultsObj[RESULT_KEYS.air] = get(scope3, "air-travel.air-travel", 0);
  ["car", "taxi", "rail", "intl-rail", "bus", "metro"].forEach((field) => {
    resultsObj[field] = get(scope3, `land-travel.${field}`, 0);
  });
  resultsObj[RESULT_KEYS.hotels] = get(scope3, "hotels.overnight-stays");

  resultsObj[RESULT_KEYS.investments] = get(scope3, "investment.total", 0);
  resultsObj[RESULT_KEYS.pensions] = get(scope3, "pension.total", 0);
  resultsObj[RESULT_KEYS.accounts] = get(scope3, "current-accounts", 0);

  if (get(scope3, "labs", 0)) {
    resultsObj[RESULT_KEYS.labs] = get(scope3, "labs", 0);
  }

  resultsObj[RESULT_KEYS.website] = get(scope3, "website", 0);

  const materials = get(scope3, "materials", null)
  if (materials) {
    resultsObj[RESULT_KEYS.materials] = materials
  }

  const distribution = get(scope3, "distribution", null)
  if (distribution) {
    resultsObj[RESULT_KEYS.distribution] = distribution
  }

  const upstreamDistribution = get(scope3, "upstream-distribution", null)
  if (upstreamDistribution) {
    resultsObj[RESULT_KEYS.upstreamDistribution] = upstreamDistribution
  }

  projectCategories
    .map((cat) => cat.name)
    .filter((cat) => cat !== "ignore")
    .forEach((category) => {
      resultsObj[category] = scope3?.purchases?.[category] || 0;
    });

  return resultsObj;
};

export const removeScope3Fields = (emissions) => {
  return {
    locations: Object.fromEntries(
      Object.entries(emissions.locations || {}).map((location) => {
        const [locationId, values] = location;

        const newValues = {
          [RESULT_KEYS.locations.elecLocation]:
            values[RESULT_KEYS.locations.elecLocation],
          [RESULT_KEYS.locations.elecMarket]:
            values[RESULT_KEYS.locations.elecMarket],
          [RESULT_KEYS.locations.ac]: values[RESULT_KEYS.locations.ac],
          [RESULT_KEYS.locations.gas]: values[RESULT_KEYS.locations.gas],
          [RESULT_KEYS.locations.fuels]: values[RESULT_KEYS.locations.fuels],
        };

        return [locationId, newValues];
      })
    ),
    [RESULT_KEYS.deliveryFleet]: {
      scope1: emissions[RESULT_KEYS.deliveryFleet]?.scope1 || 0,
      scope2: emissions[RESULT_KEYS.deliveryFleet]?.scope2 || 0,
    },
    [RESULT_KEYS.passengerFleet]: {
      scope1: emissions[RESULT_KEYS.passengerFleet]?.scope1 || 0,
      scope2: emissions[RESULT_KEYS.passengerFleet]?.scope2 || 0,
    },
  };
};

export const removePensions = (emissions) => {
  return {
    ...emissions,
    [RESULT_KEYS.pensions]: 0,
  };
};

export const removeAssets = (emissions) => {
  return {
    ...emissions,
    [RESULT_KEYS.investments]: 0,
    [RESULT_KEYS.accounts]: 0,
  };
};

export const removeFields = ({
  period,
  excludePensions,
  excludeAssets,
  isFree,
}) => {
  if (!period) return;
  let processedPeriod = period;

  if (excludePensions) {
    processedPeriod = {
      ...processedPeriod,
      emissions: removePensions(processedPeriod.emissions),
    };
  }

  if (excludeAssets) {
    processedPeriod = {
      ...processedPeriod,
      emissions: removeAssets(processedPeriod.emissions),
    };
  }
  if (isFree) {
    processedPeriod = {
      ...processedPeriod,
      emissions: removeScope3Fields(processedPeriod.emissions),
    };
  }

  return processedPeriod;
};
