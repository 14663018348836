import { cloneDeep,  set } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { analytics } from "src/adapters/common-set-up/firebase";
import { DB_PATHS } from "src/constants";
import { USER_NOTIFICATION_KEYS } from "src/constants/notificationKeys";
import {
  useDatabasePaths,
  useDesktopNotification,
  useEmail,
  useHttp,
  useOwnerNotifications,
  useSaveToLog,
  useWriteTasks,
} from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import {
  selectOrgId,
  selectOrgName,
  selectTasks,
} from "src/store/projectSelectors";
import { selectUserName } from "src/store/userSlice";
import { emailRegex } from "src/utils/common-utils/regex";
import useConvertTaskName from "./hooks/useConvertTaskName";

const AssignModalController = ({ taskToAssign }) => {
  const formMethods = useForm();

  const { handleSubmit, setValue } = formMethods;

  const { sendNewTasksEmail } = useEmail();
  const { convertTaskName } = useConvertTaskName();
  const { sendRequest } = useHttp();
  const [loading, setLoading] = useState(false);
  const org = useSelector(selectOrgId) || "none";
  const orgName = useSelector(selectOrgName);

  const { getPath } = useDatabasePaths();

  const { data: usersData } = useCollection(getPath(DB_PATHS.adminUsers));

  const { writeTasks } = useWriteTasks();

  const { setNotification } = useDesktopNotification();

  const { t } = useTranslation();

  const [sendEmails, setSendEmails] = useState(true);

  const [error, setError] = useState();

  const { addToDailyUpdateEmail } = useOwnerNotifications();

  const adminName = useSelector(selectUserName);
  const tasks = useSelector(selectTasks);

  const { saveToLog } = useSaveToLog();

  const createAccount = async ({
    email,
    password,
    org,
    firstName,
    lastName,
    role,
  }) => {
    try {
      const sendEmail =
        process.env.REACT_APP_SEND_EMAILS === "true" && sendEmails;

      await sendRequest("/app/users/user", {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          org,
          firstName,
          lastName,
          role,
          sendEmail,
          orgName,
        }),
        successMsg: t("settings.createdAccount", `Created account {{email}}!`, {
          email,
        }),
      });

      saveToLog({
        change: t("log.addedUser", { email }),
        sector: "collaborators",
      });

      addToDailyUpdateEmail({
        text: t("notifications.dailyUpdate.newUser"),
        key: email,
      });
      analytics.logEvent("assign_collaborators");
    } catch (err) {
      console.log(err);
    }

    return email;
  };

  const onSubmit = async (values) => {
    const { users, password } = values;

    if (!users?.length || !password) return;

    setLoading(true);

    const filteredUsers = users.filter(
      (user) => !!user.email && user.email.match(emailRegex)
    );

    if (!filteredUsers.length) {
      setError("Please enter valid email address(es)");
      setLoading(false);
      return;
    }
    const firstEmail = users[0]?.email;

    const promises = filteredUsers
      .filter((user) => !!user.email && user.email.match(emailRegex))
      .map(async (user) => {
        const { email, firstName, lastName, role } = user;

        return await createAccount({
          email,
          password,
          org,
          firstName,
          lastName,
          role,
        });
      });

    try {
      await Promise.all(promises).then(async (res) => {
        if (taskToAssign) {
          await writeTasks(set(cloneDeep(tasks), taskToAssign, firstEmail));

          if (process.env.REACT_APP_SEND_EMAILS === "true" && sendEmails) {
            try {
              await sendNewTasksEmail({
                email: firstEmail,
                tasks: [convertTaskName(taskToAssign)],
                adminName,
              });
            } catch (err) {
              console.error(err);
            }
          }
        }
        if (!res?.length) return;

        const adminUsers = usersData?.filter(
          ({ role }) => role === "admin" || role === "superUser"
        );

        const content = res.map((email) => {
          return {
            title: t("notifications.user.newUser.title"),
            body: t("notifications.user.newUser.body", { email }),
            key: USER_NOTIFICATION_KEYS.newUser + "-" + email,
          };
        });

        setNotification(content, adminUsers);

        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const onChange = (e) => {
    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };

  return {
    loading,
    handleSubmit,
    onSubmit,
    onChange,
    formMethods,
    setSendEmails,
    error,
  };
};

export default AssignModalController;
