import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEsgSurveyOpen } from "./store/projectSelectors";

const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const CoreData = React.lazy(() => import("./pages/coreData"));
const PurchaseData = React.lazy(() => import("./pages/purchaseData"));
const EmployeeData = React.lazy(() => import("./pages/employeeData"));
const SupplierData = React.lazy(() => import("./pages/supplierData"));
const SiteData = React.lazy(() => import("./pages/siteData"));
const FleetData = React.lazy(() => import("./pages/fleetData"));
const VolunteerData = React.lazy(() => import("./pages/volunteerData"));

const AdData = React.lazy(() => import("./pages/adData"));
const TravelData = React.lazy(() => import("./pages/travelData"));
const MaterialsData = React.lazy(() => import("./pages/materialsData"));
const PackagingData = React.lazy(() => import("./pages/packagingData"));
const DistributionData = React.lazy(() => import("./pages/distributionData"));

const UpstreamDistributionData = React.lazy(() => import("./pages/upstreamDistributionData"));

const AssessmentSurveyPage = React.lazy(() => import("./pages/adminToolPages/surveyPage"));


const TasksPage = React.lazy(() => import("./pages/tasksPage/TasksPage"));
const ForecastingData = React.lazy(() => import("./pages/forecastingData"));
// const DentistData = React.lazy(() => import("./pages/dentistData"));

const WebData = React.lazy(() => import("./pages/webData"));
const AssignedPage = React.lazy(() =>
  import("./pages/assignedPage/AssignedPage")
);
const SummaryDetails = React.lazy(() =>
  import("./pages/summaryDetails/SummaryPage")
);
const InvestmentData = React.lazy(() => import("./pages/investmentData"));

const ConstantsPage = React.lazy(() =>
  import("./pages/adminToolPages/constantsPage")
);
const CategoriesPage = React.lazy(() =>
  import("./pages/adminToolPages/categoriesPage")
);

const CommentaryPage = React.lazy(() =>
  import("./pages/adminToolPages/commentaryPage")
);
const OverridesPage = React.lazy(() =>
  import("./pages/adminToolPages/overridesPage")
);
const CollaboratorsPage = React.lazy(() => import("./pages/collaboratorsPage"));
const SupplierSettingsPage = React.lazy(() =>
  import("./pages/adminToolPages/suppliersPage")

);

const LogsPage = React.lazy(() => import("./pages/adminToolPages/logsPage"));
const RecommendationsPage = React.lazy(() =>
  import("./pages/adminToolPages/recommendationsPage")
);

const SectionsPage = React.lazy(() =>
  import("./pages/adminToolPages/sectionsPage")
);

const EvidencePage = React.lazy(() =>
  import("./pages/adminToolPages/evidencePage")
);

const OrgSettingsPage = React.lazy(() =>
  import("./pages/adminToolPages/orgSettingsPage")
);
const GlobalConstants = React.lazy(() =>
  import("./pages/globalPages/globalConstants/GlobalConstants")
);
const GlobalCategories = React.lazy(() =>
  import("./pages/globalPages/globalCategories/GlobalCategories")
);

const GlobalFactors = React.lazy(() =>
  import("./pages/globalPages/globalFactors/GlobalFactors")
);

const GlobalSections = React.lazy(() =>
  import("./pages/globalPages/globalSections")
);

const GlobalRate = React.lazy(() =>
  import("./pages/globalPages/globalRate/GlobalRate")
);

const GlobalIgnore = React.lazy(() =>
  import("./pages/globalPages/globalIgnore")
);

const SelfAssessment = React.lazy(() =>
  import("./pages/selfAssessmentSurvey")
)

const useRoutes = () => {
  const { t } = useTranslation();

  const esgSurveyOpen = useSelector(selectEsgSurveyOpen);

  const routes = [
    { path: "/", exact: true, name: t("routes.home", "Home") },
    {
      path: "/project/:id/coreData",
      name: t("coreData.title"),
      component: CoreData,
    },
    {
      path: "/project/:id/purchaseData",
      name: t("purchaseData.title"),
      component: PurchaseData,
    },
    {
      path: "/project/:id/employeeData",
      name: t("employeeData.title"),
      component: EmployeeData,
    },
    {
      path: "/project/:id/supplierData",
      name: t("supplierData.title"),
      component: SupplierData,
    },
    {
      path: "/project/:id/siteData",
      name: t("siteData.title"),
      component: SiteData,
    },
    {
      path: "/project/:id/fleetData",
      name: t("fleetData.title"),
      component: FleetData,
    },
    {
      path: "/project/:id/volunteerData",
      name: t("volunteerData.title"),
      component: VolunteerData,
    },
    {
      path: "/project/:id/adData",
      name: t("adData.title"),
      component: AdData,
    },
    {
      path: "/project/:id/travelData",
      name: t("travelData.title"),
      component: TravelData,
    },
    {
      path: "/project/:id/materialsData",
      name: t("materialsData.title"),
      component: MaterialsData,
    },
    {
      path: "/project/:id/packagingData",
      name: t("packagingData.title"),
      component: PackagingData,
    },
    {
      path: "/project/:id/distributionData",
      name: t("distributionData.title"),
      component: DistributionData,
    },
    {
      path: "/project/:id/upstreamDistributionData",
      name: t("upstreamDistributionData.title"),
      component: UpstreamDistributionData,
    },
    {
      path: "/project/:id/investmentData",
      name: t("investmentData.title"),
      component: InvestmentData,
    },
    {
      path: "/project/:id/webData",
      name: "Web",
      component: WebData,
    },
    {
      path: "/project/:id/forecastingData",
      name: t("forecastingData.title"),
      component: ForecastingData,
    },
    {
      path: "/project/:id/assigned",
      name: t("routes.assigned", "Assigned"),
      component: AssignedPage,
    },
    {
      path: "/project/:id/tasks",
      name: t("routes.tasks", "Tasks"),
      component: TasksPage,
      restrictedTo: "extended",
    },
    {
      path: "/dashboard",
      name: t("routes.dashboard", "Dashboard"),
      component: Dashboard,
    },
    {
      path: "/project/:id/summaryDetails",
      name: t("routes.summary", "Summary Details"),
      component: SummaryDetails,
    },
    {
      path: "/project/:id/constants",
      name: t("routes.constants", "Constants"),
      component: ConstantsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/categories",
      name: t("routes.categories", "Categories"),
      component: CategoriesPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/commentary",
      name: t("routes.commentary", "Commentary"),
      component: CommentaryPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/calculations",
      name: t("routes.calculations", "Calculations"),
      component: OverridesPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/supplierSettings",
      name: t("routes.supplierSettings", "Supplier Settings"),
      component: SupplierSettingsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/recommendations",
      name: "Recommendations",
      component: RecommendationsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/logs",
      name: t("routes.logs", "Logs"),
      component: LogsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/sections",
      name: "Sections",
      component: SectionsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/evidence",
      name: "Evidence",
      component: EvidencePage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/orgSettings",
      name: "Settings",
      component: OrgSettingsPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/admin-survey",
      name: "ESG survey",
      component: AssessmentSurveyPage,
      restrictedTo: "superAdmin",
    },
    {
      path: "/project/:id/collaborators",
      name: t("routes.collaborators", "Collaborators"),
      component: CollaboratorsPage,
    },
    {
      path: "/project/:orgId/:periodId/self-assessment",
      name: "ESG survey",
      component: SelfAssessment,
      restrictedTo: esgSurveyOpen ? undefined : "superAdmin",
    },
    {
      path: "/global/constants",
      name: "Global constants",
      component: GlobalConstants,
      restrictedTo: "superAdmin",
    },
    {
      path: "/global/categories",
      name: "Global categories",
      component: GlobalCategories,
      restrictedTo: "superAdmin",
    },
    {
      path: "/global/factors",
      name: "Global emission factors",
      component: GlobalFactors,
      restrictedTo: "superAdmin",
    },
    {
      path: "/global/ignore",
      name: "Ignore purchases",
      component: GlobalIgnore,
      restrictedTo: "superAdmin",
    },
    {
      path: "/global/sections",
      name: "Default data sections",
      component: GlobalSections,
      restrictedTo: "superAdmin",
    },
    {
      path: "/global/rate",
      name: "Rate settings",
      component: GlobalRate,
      restrictedTo: "superAdmin",
    },
  ];

  return { routes };
};

export default useRoutes;
